import Vue from 'vue';
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: () =>
            import ('../components/Layouts/HomeLayout.vue'),
        children: [{
            path: '/',
            component: () =>
                import ('../views/home.vue')
        }]
    },
    // {
    //     path: '/manager',
    //     component: () =>
    //         import ('../components/Layouts/HomeLayout.vue'),
    //     children: [{
    //         path: '/',
    //         name: 'manager',
    //         component: () =>
    //             import ('../views/manager.vue')
    //     }]
    // },
    // {
    //     path: '/tokenStaking',
    //     component: () =>
    //         import ('../components/Layouts/HomeLayout.vue'),
    //     children: [{
    //         path: '/',
    //         name: 'tokenStaking',
    //         component: () =>
    //             import ('../views/tokenStaking.vue')
    //     }]
    // },

]
const router = new VueRouter({
    mode: 'history',
    routes
})
export default router
